@if (categoryHelpModalComponentService.refreshOperation$ | async) {}
@if (categoryHelpModalComponentService.updateOperationFromSocket$ | async) {}
<form
  [formGroup]="categoryHelpModalComponentService.formGroup"
  (ngSubmit)="categoryHelpModalComponentService.onSubmit()"
>
  <div dougsModalTitle>
    <h6>Demande d'aide à la catégorisation</h6>
    <i class="ml-8 fal fa-times" dougsModalClose></i>
  </div>
  <div dougsModalContent="xlarge">
    @if (modalOperationStateService.currentOperation$ | async; as currentOperation) {
      <dougs-operation
        class="mb-16 category-help__operation"
        [disableCategoryHelp]="true"
        [canToggleOperationDetails]="!!categoryHelpModalComponentService.data.task"
        [showOperationDetails]="false"
        [changeUrlOnClick]="false"
        [isInOperationListModal]="true"
        [disableRefreshById]="true"
        [operation]="currentOperation"
        [synchronizedAccounts]="synchronizedAccountStateService.allSynchronizedAccounts$ | async"
      />
    } @else {
      <dougs-loader />
    }
    <dougs-form-field>
      <label dougsFormFieldLabel> À quoi correspond cette opération&nbsp;? </label>
      <textarea
        formControlName="description"
        dougsFormFieldControl
        autosize
        placeholder="Ex. : Cette opération correspond à l'assurance de mon ordinateur utilisé dans le cadre de mon entreprise."
      ></textarea>
    </dougs-form-field>
    <label dougsFormFieldLabel> Ajoutez un justificatif (optionnel) </label>
    <dougs-operation-files [operation]="modalOperationStateService.currentOperation$ | async" />

    <dougs-panel-info type="default-light" [small]="true" class="mt-16">
      <i class="fal fa-circle-info mr-8"></i>
      Plus vous nous donnerez d’informations, mieux nous pourrons vous aider à catégoriser votre opération.
    </dougs-panel-info>
  </div>
  <div dougsModalFooter>
    <dougs-button color="secondary" dougsModalClose>Annuler</dougs-button>
    @if (categoryHelpModalComponentService.data.task) {
      <dougs-button dougsModalClose>Terminer</dougs-button>
    } @else {
      <dougs-button
        type="submit"
        [disabled]="
          categoryHelpModalComponentService.formGroup.invalid || categoryHelpModalComponentService.isSending$()
        "
      >
        Envoyer ma demande
      </dougs-button>
    }
  </div>
</form>
