import { Inject, Injectable, Signal, signal, WritableSignal } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { combineLatest, concatMap, Observable, ReplaySubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CompanyStateService } from '@dougs/company/shared';
import { CompanyChangedStateService } from '@dougs/core/socket';
import { FlashMessagesService, MODAL_DATA, ModalRef } from '@dougs/ds';
import { Operation } from '@dougs/operations/dto';
import { ModalOperationStateService, SocketOperationsStateService } from '@dougs/operations/shared';
import { Task } from '@dougs/task/dto';

@Injectable()
export class CategoryHelpModalComponentService {
  formGroup: FormGroup = new FormGroup({
    description: new FormControl('', { nonNullable: true, validators: [Validators.required] }),
    file: new FormControl<File | null>(null),
  });

  private readonly setCurrentOperationSubject: ReplaySubject<number> = new ReplaySubject<number>(1);
  public setCurrentOperation$: Observable<number> = this.setCurrentOperationSubject.asObservable();
  public initialOperation!: Operation;

  refreshOperation$: Observable<Operation> = combineLatest([
    this.setCurrentOperation$,
    this.companyStateService.activeCompanyIdChanged$,
  ]).pipe(
    concatMap(([operationId, company]) =>
      this.modalOperationStateService.refreshOperationById(company.id, operationId),
    ),
    tap((operation: Operation) => (this.initialOperation = operation)),
    tap((operation: Operation) => this.modalOperationStateService.updateCurrentOperationState(operation)),
  );

  updateOperationFromSocket$: Observable<void> = this.companyChangedStateService.companyChanged$.pipe(
    concatMap((companyChanged) => this.socketOperationsStateService.updateOperationFromSocket(companyChanged)),
  );

  private readonly isSending: WritableSignal<boolean> = signal(false);
  isSending$: Signal<boolean> = this.isSending.asReadonly();

  constructor(
    @Inject(MODAL_DATA)
    public data: {
      operationId: number;
      task?: Task;
    },
    private readonly companyStateService: CompanyStateService,
    private readonly modalOperationStateService: ModalOperationStateService,
    private readonly companyChangedStateService: CompanyChangedStateService,
    private readonly socketOperationsStateService: SocketOperationsStateService,
    private readonly flashMessagesService: FlashMessagesService,
    private readonly modalRef: ModalRef,
  ) {
    this.setCurrentOperationSubject.next(this.data.operationId);

    if (this.data.task?.metadata?.context) {
      this.formGroup.get('description')?.setValue(this.data.task.metadata.context, { emitEvent: false });
    }
  }

  async onSubmit(): Promise<void> {
    if (this.formGroup.valid && !this.isSending()) {
      this.isSending.set(true);
      const hasBeenSent: Operation | null = await this.modalOperationStateService.sendOperationCategoryHelp(
        this.initialOperation,
        this.formGroup.get('description')?.value,
      );

      if (hasBeenSent) {
        this.flashMessagesService.show(
          'Demande de catégorisation envoyée. Nous vous préviendrons dès que nous l’aurons traitée.',
          {
            type: 'success',
            timeout: 5000,
          },
        );
        this.modalRef.close(true);
      }

      this.isSending.set(false);
    }
  }
}
